<div class="container">
    <div class="row p-1" style="border-bottom: 1px solid lightgray;">
        <div class="col">
            <div class="row"><p class="text-dark">{{FeedBack.comments}}</p></div>
            <br>
            <!-- <div class="row">
                <small class="text-muted">ratinged at {{FeedBack.createddatetime | FeedBack:'dd/MM/yyyy'}} </small>
            </div> -->
            <div class="row">
            <p class="text-muted">How would you rate your overall satisfaction of our service for this ticket?</p>
            </div>
            <div class="row">
                <small>
                    <i [ngClass]="FeedBack.satisfiedRating > 0 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x">
                    </i>
                    <i [ngClass]="FeedBack.satisfiedRating > 1 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.satisfiedRating > 2 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.satisfiedRating > 3 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.satisfiedRating > 4 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                </small>
            </div>
            <div class="row">
                <p class="text-muted">Please rate the updates you received on this ticket.</p>
            </div>
            
            <div class="row">
                <small>
                    <i [ngClass]="FeedBack.updatedRating > 0 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x">
                    </i>
                    <i [ngClass]="FeedBack.updatedRating > 1 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.updatedRating > 2 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.updatedRating > 3 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.updatedRating > 4 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                </small>
            </div>
            <div class="row">
                <p class="text-muted">Please rate us on the time taken to resolve the issue.</p>
            </div>
            
            <div class="row">
                <small>
                    <i [ngClass]="FeedBack.timeTakenRating > 0 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x">
                    </i>
                    <i [ngClass]="FeedBack.timeTakenRating > 1 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.timeTakenRating > 2 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.timeTakenRating > 3 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.timeTakenRating > 4 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                </small>
            </div>
           <div class="row">
            <p class="text-muted">Please rate (if applicable) the technical competence of the team to resolve the issue.</p>
           </div>
            <div class="row">
                
                <br>
                <small>
                    <i [ngClass]="FeedBack.competanceRating > 0 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x">
                    </i>
                    <i [ngClass]="FeedBack.competanceRating > 1 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.competanceRating > 2 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.competanceRating > 3 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                    <i [ngClass]="FeedBack.competanceRating > 4 ? 'text-warning' : 'text-light'" class="fa fa-star fa-2x"></i>
                </small>
            </div>
    
        </div>
    </div>
    </div>