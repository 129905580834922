import { Component, Input, OnInit } from '@angular/core';
import { TicketRating } from './TicketRating';

@Component({
  selector: 'app-view-ticket-feedbak',
  templateUrl: './view-ticket-feedbak.component.html',
  styleUrls: ['./view-ticket-feedbak.component.css']
})
export class ViewTicketFeedbakComponent implements OnInit {

  @Input() FeedBack:TicketRating;
  constructor() { }

  ngOnInit() {
    console.log(this.FeedBack);
  }

}
